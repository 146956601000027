import {
    Badge,
    Button,
    Box,
    Flex,
    Heading,
    HStack,
    Checkbox,
    Img,
    Text,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Stack,
    useColorModeValue as mode,
    Link,
    Image,
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import goodworkLogo from '../../../assets/images/logo-black.svg';
import SessionContext from '../../../context/SessionContext';
import UnderlineLink from '../../ui/underline-link/UnderlineLink';
import useApi from '../../../hooks/useApi';

function SignupStartForm() {
    const history = useHistory();
    const api = useApi();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');

    const onClick = async () => {
        setLoading(true);
        try {
            const signupStartResponse = await api.requestRegister(email);
            setLoading(false);
        } catch (e) {
            setError('' + e.message);
            setLoading(false);
        }
    }

    return (
        <Box
            margin={{base: '0 auto', lg: '0'}}
            flex="0 0 auto"
            maxW="500px"
            bgColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            alignItems="stretch"
            alignSelf={{base: 'center', lg: 'flex-start'}}
        >
            <Heading size="md" mb={2}>Join Goodwork</Heading>
            <Text mb={8}>Let's get started with your email. We'll send an invitation link.</Text>
            <Stack spacing="-px">
                <FormControl id="email-address" isInvalid={error}>
                    <FormLabel srOnly>Email address</FormLabel>
                    <Input
                    size="lg"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email address"
                    bg={mode('white', 'gray.700')}
                    fontSize="md"
                    />
                    <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
            </Stack>
            <Button
                size="lg"
                type="submit"
                mt="4"
                w="full"
                colorScheme="blue"
                fontSize="md"
                fontWeight="bold"
                onClick={onClick}
                isLoading={loading}
            >
                Send Email
            </Button>
            <Flex align="center" justify="flex-end" mt={4}>
                <UnderlineLink fontSize="sm" href={'/auth/login'}>Back to Login</UnderlineLink>
            </Flex>
            <Box h={10} />
        </Box>
    )
}

export default SignupStartForm;