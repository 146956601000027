import React, { useEffect, useState } from 'react';
import ApiService from '../services/api.service';
import { useLocalStorage } from 'react-use';

const SessionContext = React.createContext(null);

const LoginStates = {
    LOGGING_IN: 'logging-in',
    LOGGED_IN: 'logged-in',
    LOGGING_OUT: 'logging-out',
    LOGGED_OUT: 'logged-out'
};

/**
 * Stores and manages a user session.
 * Uses local storage to cache the user's credentials.
 * 
 * Automatically checks the credential validity and logs the user out
 * if their session is invalid/expired.
 * 
 * Manages an instance of the API wrapper with the correct auth token.
 */
function SessionContextProvider({ children }) {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [api, setApi] = useState(new ApiService(null));

    const [accessToken, setAccessToken] = useLocalStorage('loginToken', null);
    const [loginState, setLoginState] = useState(LoginStates.LOGGING_IN);

    const login = async (username, password, rememberMe) => {
        // TODO: Attempt to login
        const loginResponse = await api.login(username, password);


        // TODO: If login succeeded, set a new session token
        sessionStorage.setItem('loginToken', '123');
        setLoginState(LoginStates.LOGGING_IN);

        // Grab the user and account details and store for access throughout the application
        setUser(null);
        setAccount(null);
        
        // TODO: If login succeeded, set new application state 
        // so that the app redirects to the main view
        setLoggedIn(true);
        setLoginState(LoginStates.LOGGED_IN);
    }

    const checkToken = async (accessToken) => {
        const newApi = new ApiService(accessToken);

        try {
            const tokenStatus = await newApi.checkToken(accessToken);
            setApi(newApi);
            setLoggedIn(true);
            setLoginState(LoginStates.LOGGED_IN);
        } catch (e) {
            // Failed to validate the token.
            await logout();
        }
    };

    const logout = async () => {
        sessionStorage.setItem('loginToken', null);
        setLoggedIn(false);
        setLoginState(LoginStates.LOGGED_OUT);
        setUser(null);
        setAccount(null);
    }

    useEffect(() => {
        // TODO: On mount, check if session token exists.
        // If so, attempt to instantiate the API with the existing token.
        const token = sessionStorage.getItem('loginToken');
        if (token) {
            checkToken(token);
        } else {
            logout();
        }
    }, []);

    useEffect(() => {
        // TODO: Periodically check that the session token is still valid.
        // If the session has expired, log the user out.
    }, []);

    return (<SessionContext.Provider value={{
        user,
        loggedIn,
        loading: loginState === LoginStates.LOGGING_IN || loginState === LoginStates.LOGGING_OUT,
        api,
        login,
        logout
    }}>
        {children}
    </SessionContext.Provider>);
}

export { SessionContextProvider };
export default SessionContext;