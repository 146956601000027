import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import SessionContext from '../../../../context/SessionContext'
import { AccountSwitcherButton } from './AccountSwitcherButton'

export const AccountSwitcher = () => {
  const history = useHistory();
  const { logout } = useContext(SessionContext);


  return (
    <Menu flex="0 0 auto">
      <AccountSwitcherButton />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        <Text fontWeight="medium" mb="2">
          cdalke@joingoodwork.com
        </Text>
        <MenuOptionGroup defaultValue="chakra-ui">
          <MenuItemOption value="chakra-ui" fontWeight="semibold" rounded="md">
            Team One
          </MenuItemOption>
          <MenuItemOption value="careerlyft" fontWeight="semibold" rounded="md">
            Team Two
          </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem rounded="md">Workspace settings</MenuItem>
        <MenuItem rounded="md">Manage teams</MenuItem>
        <MenuDivider />
        <MenuItem rounded="md" onClick={() => logout()}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
