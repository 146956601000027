import { extendTheme } from "@chakra-ui/react"

const Spinner = {
    defaultProps: {
        thickness: '5px',
        emptyColor: "gray.200"
    }
}

const theme = extendTheme({
    components: {
        Spinner
    }
});

export default theme;