import { Box, Image } from "@chakra-ui/react";
import loginBg from '../../../assets/images/login-bg.svg';

function ShapeSimpleBackground() {
    return (
        <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            overflow="hidden"
            bgColor="#080717"
            display="flex"
            justifyContent="stretch"
            alignItems="stretch"
            margin="-50px"
        >
            <Image flex="1" objectFit="cover" src={loginBg} />
        </Box>
    );
}

export default ShapeSimpleBackground;