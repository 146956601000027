import { useContext } from "react";
import SessionContext from "../context/SessionContext";

/**
 * Returns the API instance.
 */
function useApi() {
    const { api } = useContext(SessionContext);
    return api;
}

export default useApi;