import { Box, Spinner, Image } from "@chakra-ui/react";

import goodworkLogo from '../../../assets/images/logo-black.svg';

/*
Display a loading spinner.
Used to transition between logged out and logged in
*/
function LoadingScreen() {
    return (
        <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
        >
            <Spinner
                size="xl"
                thickness="3px"
                speed='0.6s'
                emptyColor='gray.200'
                color='black'
                mb={5}
            />
                <Image src={goodworkLogo} width="150px" flex="0 0 auto" />
        </Box>
    )
}

export default LoadingScreen;