import { Box, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export const NavItem = (props) => {
  const history = useHistory();
  
  const { subtle, icon, children, label, endElement, path, onClick } = props
  const match = useRouteMatch({
    path,
    strict: true,
    sensitive: true,
    exact: true
  });
  const active = match;
  return (
    <HStack
      as="a"
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'gray.700' : undefined}
      _hover={{
        bg: 'gray.700',
      }}
      _active={{
        bg: 'gray.600',
      }}
      onClick={onClick ? onClick : () => history.push(path)}
    >
      <Box fontSize="lg" color={active ? 'currentcolor' : 'gray.400'}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit" color={subtle ? 'gray.400' : undefined}>
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  )
}
