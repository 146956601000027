import Sidebar from "../../features/sidebar/Sidebar";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
  } from "react-router-dom";
import { App } from './test/App';

function LoggedInScreen() {
    // TODO: Show the sidebar on all screens, all the time

    // TODO: Render separate container for sidebar and right scroll;
    // render sub-pages

    return (
        <App/>
    );
}

export default LoggedInScreen;