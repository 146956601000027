import { Box, BoxProps } from '@chakra-ui/react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

const UnderlineLink = (props) => {
  const history = useHistory();
  return (
    <Box
      as="a"
      href="#"
      pos="relative"
      display="inline-block"
      transition="opacity 0.2s"
      _hover={{ opacity: 0.8 }}
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: '2px',
        bottom: 0,
        bg: 'gray.300',
        insetX: 0,
        insetY: 0,
      }}
      {...props}
      onClick={(e) => {
        history.push(props.href || '#');
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  )
}

export default UnderlineLink;