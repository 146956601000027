import RestApiWrapper from '../utils/RestApiWrapper';

/**
 * A wrapper around the GoodWork API.
 * Instantiates connections, provides wrapper for common operations, 
 * manages login sessions, etc.
 */
class ApiService {
    constructor(token) {
        this.token = token;
        this.api = new RestApiWrapper(this.token);
    }

    async sendFeedback(feedbackText, context, userId) {
        return this.api.post('/api/v1/public/feedback', {
            feedbackText,
            context,
            userId
        });
    }

    async healthcheck() {
        return this.api.get('/api/v1/healthcheck');
    }

    async checkToken() {
        return this.api.get('/api/v1/auth/whoami');
    }

    async login(username, password) {
        return this.api.post('/api/v1/auth/login', {
            username,
            password
        });
    }

    async logout() {
        return this.api.post('/api/v1/auth/logout');
    }

    async whoAmI() {
        return this.api.get('/api/v1/auth/whoami');
    }

    async requestPasswordReset() {
        return this.api.post('/api/v1/passwordReset/start');
    }

    async finishPasswordReset(passwordResetToken, newPassword) {
        return this.api.post('/api/v1/passwordReset/finish', {
            passwordResetToken,
            password: newPassword
        });        
    }

    async requestRegister(email) {
        return this.api.post('/api/v1/register/start', {
            email
        });
    }

    async finishRegister(token) {
        return this.api.post('/api/v1/register/finish', {
            token
        });
    }
}

export default ApiService;