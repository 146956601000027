import {
    Badge,
    Button,
    Box,
    Flex,
    Heading,
    HStack,
    Checkbox,
    Img,
    Text,
    FormControl,
    FormLabel,
    Input,
    Stack,
    useColorModeValue as mode,
    Link,
    Image,
    ScaleFade,
    Collapse
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import goodworkLogo from '../../../assets/images/logo-black.svg';

import vectorArcSquare from '../../../assets/images/vector-shapes/arc-square.svg';
import vectorCircle from '../../../assets/images/vector-shapes/circle.svg';
import vectorCube from '../../../assets/images/vector-shapes/cube.svg';
import vectorSemicircle from '../../../assets/images/vector-shapes/semicircle.svg';
import vectorTrapezoid from '../../../assets/images/vector-shapes/trapezoid.svg';

import SessionContext from '../../../context/SessionContext';
import LoginForm from '../../features/login-form/LoginForm';
import UnderlineLink from '../../ui/underline-link/UnderlineLink';
import PasswordRecoveryForm from '../../features/password-recovery-form/PasswordRecoveryForm';
import SignupStartForm from '../../features/signup-start-form/SignupStartForm';
import ShapeSimpleBackground from '../../features/shape-simple-bg/ShapeSimpleBackground';

const LoginScreenSection = {
    None: 'none',
    Login: 'login',
    ForgotPassword: 'forgot-password',
    StartSignup: 'start-sign-up'
}

function LoginScreen() {
    let loginSection = LoginScreenSection.None;

    let loginMatch = useRouteMatch({
        path: "/auth/login",
        exact: true
    });
    let forgotPasswordMatch = useRouteMatch({
        path: "/auth/forgot-password",
        exact: true
    });
    let startSignupMatch = useRouteMatch({
        path: "/auth/register",
        exact: true
    });

    if (loginMatch) {
        loginSection = LoginScreenSection.Login;
    }
    if (forgotPasswordMatch) {
        loginSection = LoginScreenSection.ForgotPassword;
    }
    if (startSignupMatch) {
        loginSection = LoginScreenSection.StartSignup;
    }

    return (
        <Flex
            flex="1"
            direction="row"
        >
            <Box
                flex="1"
                overflow="hidden"
                bgColor="#080717"
                display={{base: 'none', lg: 'block'}}
                position="relative"
                zIndex="0"
            >
                <ShapeSimpleBackground />
            </Box>
            <Box
                flex="1"
                dropShadow="dark-lg"
                zIndex="1"
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                alignItems="stretch"
                borderLeft="1px solid rgba(0,0,0,0.2)"
                paddingLeft={{base: '40px', lg: '50px'}}
                paddingRight={{base: '40px', lg: '50px'}}
            >
                <Flex
                    flex="0 0 auto"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="stretch"
                    alignSelf={{base: 'center', lg: 'flex-start'}}
                    mt={10} mb={10} 
                >
                <Image src={goodworkLogo} width="250px" flex="0 0 auto" />
                </Flex>
                <Box flex="1" />
                <Collapse in={loginSection === LoginScreenSection.Login} unmountOnExit>
                    <LoginForm />
                </Collapse>
                <Collapse in={loginSection === LoginScreenSection.StartSignup} unmountOnExit>
                    <SignupStartForm />
                </Collapse>
                <Collapse in={loginSection === LoginScreenSection.ForgotPassword} unmountOnExit>
                    <PasswordRecoveryForm />
                </Collapse>
                <Box flex="1" />
                {loginSection === LoginScreenSection.None && (
                    <Redirect to="/auth/login" />
                )}
            </Box>

        </Flex>
    );
}

export default LoginScreen;