import { Box, Circle, Flex, Heading, Image, Stack, Text, useColorModeValue as mode, Container , Tabs, TabPanel, Button, HStack, TabList, Tab, Divider, TabPanels} from '@chakra-ui/react'
import { useContext } from 'react'
import {
  BiBuoy,
  BiCog,
  BiCommentAdd,
  BiLinkExternal,
  BiCreditCard,
  BiEnvelope,
  BiReceipt,
  BiHome,
  BiNews,
  BiPurchaseTagAlt,
  BiRecycle,
  BiRedo,
  BiUserCircle,
  BiBulb,
  BiWallet,
  BiChat
} from 'react-icons/bi';
import { HiChartBar, HiDownload, HiPlus } from 'react-icons/hi';
import { AccountSwitcher } from './AccountSwitcher'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'
import goodworkLogo from '../../../../assets/images/logo-white.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import SessionContext from '../../../../context/SessionContext'
import { useHistory } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import FeedbackPopover from '../../../features/feedback-popover/FeedbackPopover'

export const App = () => {
  const history = useHistory();

  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" id="app-container" justifyContent="stretch" alignItems="stretch">
        <Box w="64" bg="gray.900" color="white" fontSize="sm" overflow="auto" style={{
          colorScheme: 'dark'
        }}
          display="flex" flexDirection="column" justifyContent="stretch" alignItems="stretch"
        >
          <Flex flex="0 0 auto" direction="column" h="120px" alignItems="stretch" justifyContent="stretch">
            <Image flex="0 0 auto" alignSelf="center" src={goodworkLogo} w="110px" mt={5} />
            <Box flex="1" />
            <AccountSwitcher />
          </Flex>
          <Divider borderBottomWidth="2px" opacity={1} borderColor={'gray.800'} />
          <Flex flex="1" direction="column" py="4">
            <Stack spacing="8" flex="1" pt="2" px="4" >
              <Stack spacing="1">
                <NavItem icon={<BiHome />} label="Dashboard" path="/" />
              </Stack>
              <NavGroup label="Work">
                <NavItem icon={<BiBulb />} label="Projects" path="/projects" />
                <NavItem icon={<BiReceipt />} label="Time Tracking" path="/timeTracking" />
                <NavItem icon={<BiUserCircle />} label="Customers" path="/customers" />
              </NavGroup>
            </Stack>
            <Box pb="4" px="4" >
              <Stack spacing="1">
                <NavItem subtle icon={<BiCog />} label="Settings" path="/settings" />
                <FeedbackPopover>
                  <NavItem subtle icon={<BiChat />} label="Feedback" />
                </FeedbackPopover>
                <NavItem
                  subtle
                  icon={<BiBuoy />}
                  label="Help & Support"
                  endElement={<BiLinkExternal />}
                  onClick={() => window.open('https://joingoodwork.com', '_blank')}
                />
              </Stack>
            </Box>
          </Flex>
        </Box>
        <Box bg={mode('white', 'gray.800')} flex="1 1 auto">
          <Switch>
              <Route path="/" exact>
              <Tabs isFitted>
                  <Flex direction="column" align="stretch" minH="100vh">
                    <Box bg={mode('gray.50', 'gray.800')} px="8" pt="8" h="120px">
                      <Box maxW="7xl" mx="auto">
                        <Flex
                          direction={{ base: 'column', md: 'row' }}
                          justify="space-between"
                          align="flex-start"
                          mb="8"
                        >
                          <HStack mb={{ base: '4', md: '0' }}>
                            <Heading size="lg">Your Dashboard</Heading>
                          </HStack>
                        </Flex>
                      </Box>
                    </Box>
                    <Box pos="relative" zIndex={0}>
                      <Divider borderBottomWidth="2px" opacity={1} borderColor={mode('gray.100', 'gray.700')} />
                    </Box>
                    <Box px="8" flex="1">
                      <Box maxW="7xl" mx="auto">
                        <TabPanels mt="5" h="full">
                          <TabPanel><b>You're doing good work!</b></TabPanel>
                        </TabPanels>
                      </Box>
                    </Box>
                  </Flex>
                </Tabs>
              </Route>
              <Route path="/settings">
                <Heading>Settings</Heading>
              </Route>
              <Route path="/projects">
                <Heading>Projects</Heading>
              </Route>
              <Route path="/timeTracking">
                <Heading>Time Tracking</Heading>
              </Route>
              <Route path="/customers">
                <Heading>Customers</Heading>
              </Route>
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
        </Box>
      </Flex>
    </Box>
  )
}
