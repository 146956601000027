import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Portal
  } from '@chakra-ui/react'

function FeedbackPopover({ children }) {
    return (
        <Popover>
            <PopoverTrigger>
                {children}
            </PopoverTrigger>
            <Portal>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Feedback</PopoverHeader>
                <PopoverBody>Submit feedback here</PopoverBody>
            </PopoverContent>

            </Portal>
        </Popover>
    );
}

export default FeedbackPopover;