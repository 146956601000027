import {
    Badge,
    Button,
    Box,
    Flex,
    Heading,
    HStack,
    Checkbox,
    Img,
    Text,
    FormControl,
    FormLabel,
    Input,
    Stack,
    useColorModeValue as mode,
    Link,
    Image,
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import goodworkLogo from '../../../assets/images/logo-black.svg';
import SessionContext from '../../../context/SessionContext';
import UnderlineLink from '../../ui/underline-link/UnderlineLink';

function LoginForm({ smallScreen }) {
    const history = useHistory();
    const { login } = useContext(SessionContext);

    const [rememberMe, setRememberMe] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
        <Box
            margin={{base: '0 auto', lg: '0'}}
            flex="0 0 auto"
            maxW="500px"
            bgColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            alignItems="stretch"
            alignSelf={{base: 'center', lg: 'flex-start'}}
        >
            <Heading size="md" mb={2}>Sign in to your account</Heading>
            <Text mb={8}>Need an account? <UnderlineLink href="/auth/register">Sign up for free</UnderlineLink></Text>
            <Stack spacing="-px">
                <FormControl id="email-address">
                    <FormLabel srOnly>Email address</FormLabel>
                    <Input
                    size="lg"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email address"
                    bg={mode('white', 'gray.700')}
                    fontSize="md"
                    roundedBottom="0"
                    />
                </FormControl>
                <FormControl id="password">
                    <FormLabel srOnly>Email address</FormLabel>
                    <Input
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    size="lg"
                    bg={mode('white', 'gray.700')}
                    fontSize="md"
                    roundedTop="0"
                    placeholder="Password"
                    />
                </FormControl>
            </Stack>
            <Flex align="center" justify="space-between" mt={4}>
                <Checkbox
                size="lg"
                colorScheme="blue"
                sx={{
                    '.chakra-checkbox__control': {
                    '&:not([data-checked])': { bg: mode('white', 'gray.700') },
                    rounded: 'base',
                    borderWidth: '1px',
                    },
                    '.chakra-checkbox__label': { fontSize: 'sm' },
                }}
                >
                Remember me
                </Checkbox>
            <UnderlineLink fontSize="sm" href={'/auth/forgot-password'}>Forgot Password</UnderlineLink>
            </Flex>
            <Button
                size="lg"
                type="submit"
                mt="8"
                w="full"
                colorScheme="blue"
                fontSize="md"
                fontWeight="bold"
                onClick={() => login(username, password, rememberMe)}
            >
                Sign in
            </Button>
            <Box h={10} />
        </Box>
    )
}

export default LoginForm;